import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import Button from "../components/Button"
import { PagerStyles } from "../styles/BlogStyles"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const BlogTemplate = (props) => {
  const { edges } = props.data.allMarkdownRemark

  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <>
      <Seo title="Read more about my thoughts" />
      <Layout>
        <Banner content="My Blog" />
        <p>
          {" "}
          This is where you will find all my blog posts
        </p>
        {edges.map(({ node }, index) => {
          return <BlogItem nodeObj={node} index={index} />
        })}
        {/* Paging controls
        If there are multiple pages, show pager */}
        {numPages > 1 && (
          <PagerStyles>
            <div className="btns">
              {!isFirst && (
                <Link to={`/blog/${prevPage}`} rel="prev">
                  <Button text="Previous" />
                </Link>
              )}
              {!isLast && (
                <Link to={`/blog/${nextPage}`} rel="next">
                  <Button text="Next" />
                </Link>
              )}
            </div>
            <div className="numbers">
              {Array.from({ length: numPages }, (_, i) => (
                <Link
                  key={`pagination-numbers${i + 1}`}
                  to={`/blog/${i === 0 ? "" : i + 1}`}
                >
                  {i + 1}
                </Link>
              ))}
            </div>
          </PagerStyles>
        )}
      </Layout>
    </>
  )
}

export default BlogTemplate

export const blogQuery = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YY")
            path
            tags
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
